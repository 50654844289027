/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
/* Desktop Responsiveness */
.org-page_details,
.org-list {
    max-width: 33rem;
    margin: 0 auto;
}

.org-events,
.org-event_page {
    max-width: 35rem;
    margin: 0 auto;
}
/* End Desktop Responsiveness */

.org-title {
    text-align: center !important;
    margin-left: 0;
}

.org-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    height: 3.0rem;

    background-color: var(--swatch-04);
    z-index: 2; /* You may need to adjust this value based on other elements on your page */

    width: 100vw;
}

.org-nav > .fa-button {
    cursor: pointer;
}

.org-callout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5rem;
}

.org-callout > h6 {
    margin: 0;
    margin-left: 1rem;
}

.org-events {
    overflow: auto;
}

.org-list_subscription_recent_costs {
    background: var(--swatch-12);
    box-shadow: 0 0.5rem 2rem 0 rgba( 31, 38, 135, 0.37 );
    border-radius: 0.75rem;
    margin: 1rem;
    margin-top: 0rem;
    padding: 1.5rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.org-list_subscription_recent_costs-full_details_button {
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    margin-left: 1rem;

    color: var(--swatch-06);
    width: fit-content;

    background: var(--swatch-12);
    border-radius: 0.75rem;

    transform: translate(0%, 1.5rem);
}

.org-list_subscription_recent_costs-full_details_button:hover {
    color: var(--swatch-08);
    cursor: pointer;
}

.org-list_subscription_recent_costs-full_details_button > svg {
    margin-right: 0.25rem;
}

.org-list_subscription_recent_costs-amount {
    background: -moz-linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-04) 0%, var(--swatch-07) 80%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    font-weight: var(--weight-03);
    border-radius: 2rem;
    padding: 0.75rem;

    color: var(--swatch-10);
    min-width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.org-list_subscription_recent_costs-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.org-list_subscription_recent_costs-details > p {
    padding-bottom: 1rem;
    max-width: 20rem;
    margin-left: 0;
    margin-right: 0;
}

.org-list_subscription_recent_costs-details > p.c2a {
    border: 0.125rem solid var(--swatch-04);
    background: -moz-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom right, var(--swatch-09) 0%, var(--swatch-12) 80%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.org-intro > .card,
.org-list > .card,
.org-page_details > .card {
    padding: 1rem;
    margin: 1.5rem;
}

.org-page_details > .card {
    margin-bottom: 3rem;
}

.org-page_details > .card > h2 {
    margin: 0;
    text-align: center;
}

.org-intro > .card > h4,
.org-list > .card > h4,
.org-list > .card > h6 {
    margin-left: 0;
}

.org-list-label {
    text-align: left !important;
    margin-right: 4rem;
}

.org-list-label_subscription-details {
    margin-top: 1rem;
    font-size: var(--size-06);
    font-weight: var(--weight-03);
    
    color: var(--swatch-12);
    background: -moz-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-02) 10%, var(--swatch-08) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    
    border: 0.0625rem outset var(--swatch-10);
    border-radius: 0.5rem;

    width: fit-content;
}

.org-list-label.social_link {
    margin-bottom: 0.75rem;
}

.org-id {
    color: var(--swatch-09)
}

.org-more {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.org-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}

.org-modal > h5 {
    margin: 0;
}

.event-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.org-sticky_button_wrapper {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background: var(--swatch-10);
    border-top: 1px solid var(--swatch-03);
}

.org-sticky_button_wrapper > .org-nav > a > .button > svg {
    margin-right: 0.5rem;
}

.org-page_details .org-primary_category {
    display: flex;
    justify-content: center;
    margin: 1rem 0 0 0;
}

.org-page_details .org-primary_category .org-info_label {
    margin: 0;
}
    
.org-info_label {
    background-color: var(--swatch-12);
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    margin-left: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
}

.org-info_description {
    padding: 1rem;
}

.org-event_image_update_container {
    padding: 0rem 2rem;
}

.org-event_image_update_container > .button {
    margin: 0;
}

address {
    font-style: normal;
}

address > p {
    margin-top: 0 !important;
}

.event_nav > div > b > .button:hover,
.event-modal > .org-nav > .button:hover {
    background-color: inherit;
}

.primary_star_button {
    position: absolute;
    top: 0;
    right: 0;
    min-width: inherit;

    background: -moz-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    color: var(--swatch-12);
    border: 0.125rem outset var(--swatch-10);
}

.org-more > .primary_star_button > svg {
    margin-right: 0;
}