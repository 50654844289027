/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.events {
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.event-switch-container {
    display: flex;
    justify-content: flex-end;
    font-size: 0.75rem;
    padding: 0.5rem;
}

.event-switch {
    display: flex;
}

.event-switch > .button {
    margin: 0;
    border-radius: 0rem;
    height: 1.25rem;
    border: .0625rem inset var(--swatch-12);
}

.event-switch > .button:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.event-switch > .button:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.event-switch > .button:hover {
    background-color: var(--swatch-04);
}

.event-switch > .button.inactive {
    background-color: var(--swatch-09);
    color: var(--swatch-04);
    border: .0625rem outset var(--swatch-06)
}

.event-switch-microcopy {
    text-align: right;
    margin-right: 0.5rem;
    font-weight: var(--weight-03);
}

.search_input-container {
    flex-grow: 1;
    margin: 0 1rem; /* Adjust as needed */
}

.search_input-container input {
    
    width: 100%; /* Add this line */
    height: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    border-radius: 0.75rem; /* Add this line */

    outline: none; /* Add this line */
    border: none; /* Add this line */
}

@media (max-width: 768px) {
    #login {
        display: none;
    }
}