/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
/* Simple Animation Generic Class */

.animation-examples {
    padding: 1rem;
}

.animatedBackground {
    background-color: var(--animation-color);

    padding: 1rem;
    margin: 1rem;
    width: 1rem;
    height: 1rem;

    border-radius: 0.5rem;
}

#animation-simple-examples {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#animation-simple-examples > label {
    padding-bottom: 2rem;
}

/* Simple Animations */

@keyframes scaleUp {
    from { transform: scale(1); }
    to { transform: scale(1.5); }
}

.scaleUpAnimation {
    animation: scaleUp var(--animation-duration) infinite;
}

@keyframes slide {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(100%);
    }
}

.slideAnimation {
    animation: slide var(--animation-duration) infinite;
}
  
@keyframes fade {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
  
.fadeAnimation {
    animation: fade var(--animation-duration) infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

.pulseAnimation {
    animation: pulse var(--animation-duration) infinite;
}


@keyframes rotate {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
  
.rotateAnimation {
    animation: rotate var(--animation-duration) infinite;
} 

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}
  
.blinkAnimation {
    animation: blink var(--animation-duration) infinite;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}
  
.bounceAnimation {
    animation: bounce var(--animation-duration) infinite;
}
 
@keyframes flip {
    from { transform: rotateY(0deg); }
    to { transform: rotateY(180deg); }
}
  
.flipAnimation {
    animation: flip var(--animation-duration) infinite;
}

@keyframes swing {
    0%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(15deg); }
    75% { transform: rotate(-15deg); }
}
  
.swingAnimation {
    animation: swing var(--animation-duration) infinite;
}

@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
}
  
.shakeAnimation {
    animation: shake var(--animation-duration) infinite;
}

@keyframes glow {
    0%, 100% { box-shadow: 0 0 5px var(--swatch-01); }
    50% { box-shadow: 0 0 20px var(--swatch-01); }
}
  
.glowAnimation {
    animation: glow var(--animation-duration) infinite;
}
  
@keyframes expand {
    from { width: 10%; }
    to { width: calc(100% - 4rem); }
}
  
.expandAnimation {
    animation: expand var(--animation-duration) infinite;
}

/* Custom Animations - Sliding Background */

.sliding-animation-container {
    position: relative; /* This is crucial for containing everything */
    overflow: hidden; /* Ensures no overflow from moving backgrounds */
    width: calc(100% - 2rem); /* Or specific width as needed */
    height: 2rem; /* Example height, adjust as necessary */

    background-color: var(--swatch-01);
    
    margin: 2rem 1rem;
    border-radius: 0.5rem;

    border: 0.0625rem outset var(--swatch-01);
}

.bg, .bg2, .bg3 {
    background-image: linear-gradient(
        -60deg, 
        var(--swatch-09) 50%, 
        var(--swatch-12) 50%
    );
    position: absolute;
    width: 200%; /* Wider to allow for sliding effect */
    height: 100%;
    opacity: 0.5;
    animation: multi_slide 3s ease-in-out infinite alternate;
}

.bg2, .bg4 {
    animation-direction: alternate-reverse;
}

.sliding-animation-copy {
    font-weight: var(--weight-03);
    position: absolute;
    width: 100%; /* Adjust content width as necessary */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--swatch-01);
    padding: 1rem; /* Adjust padding as necessary */
    box-sizing: border-box;
}

@keyframes multi_slide {
    0% {
        transform: translateX(-50%);
    }
    100% {
        transform: translateX(0);
    }
}

/* Custome Animations - Rotating Background */

.moving-gradient-background {
    background: linear-gradient(
        132deg, 
        var(--swatch-01), 
        var(--swatch-08), 
        var(--swatch-12)
    );
    background-size: 400% 400%;
    
    animation: gradient_background_rotation 15s ease infinite;
    
    position: relative; /* This is crucial for containing everything */
    overflow: hidden; /* Ensures no overflow from moving backgrounds */
    width: calc(100% - 2rem); /* Or specific width as needed */
    height: 2rem; /* Example height, adjust as necessary */

    background-color: var(--swatch-01);
    
    margin: 2rem 1rem;;
    border-radius: 0.5rem;

    border: 0.0625rem outset var(--swatch-01);
}

.moving-gradient-copy {
    font-weight: var(--weight-03);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: 1rem;
}

/* Animate Background*/
@keyframes gradient_background_rotation {
    0% {
        background-position: 0% 0%;
        color: var(--swatch-12);
    }
    47% {
        background-position: 100% 50%;
        color: var(--swatch-01);
    }
    50% {
        background-position: 0% 0%;
        color: var(--swatch-12);
    }
    53% {
        background-position: 100% 50%;
        color: var(--swatch-01);
    }
    100% {
        background-position: 100% 100%;
    }
}
  
@media (prefers-reduced-motion) {
    .bg, .bg2, .bg3,
    .moving-gradient-background,
    .error-message > * {
        animation: none !important;
    }

    .moving-gradient-background {
        color: var(--swatch-12);
    }
}