.connected-event_group {
    background: -moz-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-12) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 0.0625rem outset var(--swatch-04);
    border-radius: 0 0 0.5rem 0.5rem;
}

.connected_vibe_label {
    margin: 0.25rem 0.5rem ;
    background: -moz-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-10) 10%, var(--swatch-09) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  
    width: fit-content;
  
    color: var(--swatch-04);
  
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba( 31, 38, 135, 0.37 );
    padding: 0.0625rem 0.5rem;
    border-radius: 0.5rem;
  
    border: 0.0625rem outset var(--swatch-08);
  
    display: flex;
    justify-content: center;
    align-items: center;
}
