/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.event_nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 3.0rem;

  background-color: var(--swatch-04);
  z-index: 2; /* You may need to adjust this value based on other elements on your page */

  width: 100vw;
}

.event_nav.page_info {
    justify-content: end;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.event_nav.page_info > div {
    padding: 0.25rem;
}

.event_button,
.event_button a {
  cursor: pointer;
  user-select: none;
  padding: 1.7em;
  height: 1.5rem;
  color: var(--swatch-07);
  border: none;
  transition: all 0.3s ease; /* Smooth hover effect */
}

.event_button_current,
.event_button_current a {
    
  padding: 1.7em;
  color: var(--swatch-03);
}

.event_button:hover,
.event_button a:hover {
  color: var(--swatch-08);
}

.event_button_current:hover,
.event_button_current a:hover {
  color: var(--swatch-06);
}
