/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
#event-list-preview {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 0.5rem 0;

    border: 0.0625rem outset var(--swatch-06);
    border-radius: 1rem;

    background: -moz-linear-gradient(to top right, var(--swatch-09) 10%, var(--swatch-12) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-09) 10%, var(--swatch-12) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-09) 10%, var(--swatch-12) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

#event-list-preview .disclaimer {
    padding: 1rem 0rem;
    text-align: center;
    margin: 0;
}

#event-list-preview .guide_text {
    font-weight: var(--weight-03);
    text-align: center;
    margin: 0.5rem 1rem;
}

#event-list-preview .events {
    margin: 1rem;
    padding: 0.25rem 1rem;
    border: 0.0625rem inset var(--swatch-08);
    border-radius: 1rem;

    height: 7rem;
    overflow-y: auto;

    background: -moz-linear-gradient(to top, var(--swatch-09) 10%, var(--swatch-10) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top, var(--swatch-09) 10%, var(--swatch-10) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, var(--swatch-09) 10%, var(--swatch-10) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

#event-list-preview .button {
    border: 0.125rem outset var(--swatch-12);

    background: -moz-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-04) 10%, var(--swatch-06) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

#event-list-preview .button svg {
    margin-right: 0.5rem;
}

#event-list-preview .events .card {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;

    box-shadow: 0 0.5rem 0.5rem 0 rgba(31, 38, 135, 0.37);

    background: var(--swatch-10);
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(0.25rem);
    -webkit-backdrop-filter: blur(0.25rem);
    border-radius: 0.75rem;
    border: 0.0625rem outset var(--swatch-12);
    max-width: 32rem;

    padding: 0rem;
    width: inherit;
}

#event-list-preview .events .card > .forward {
    color: var(--swatch-06);
    width: calc(100% - 6rem);
    
    transform: inherit;
    flex-grow: 1;
    margin: 0.125rem;
    padding: 0.5rem 0;
    margin-left: -0.25rem;
    
    border-radius: 0.75rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-shadow: 0 0.125rem 0.125rem 0 rgba(31, 38, 135, 0.37);

    border: 0.0625rem outset var(--swatch-12);
    background: var(--swatch-12);
    z-index: 1;
}

#event-list-preview .events .card > .forward > div {
    padding: 0 0.5rem 0 .5rem;
}

#event-list-preview .events .card > .forward > .event_name {
    overflow: hidden;         /* Hide overflowing content */
    display: -webkit-box;     /* Use flexbox layout */
    -webkit-line-clamp: 2;    /* Limit to two lines */
    -webkit-box-orient: vertical; /* Set the box orientation to vertical */
    
    font-size: var(--size-06);
    text-align: center;

    margin: 0 0.5rem;
}

#event-list-preview .events .card > .forward > .event-view_vibe {
    display: flex;
    justify-content: flex-end;   
}

#event-list-preview .events .card > .forward > .event-view_vibe .link_to_vibe {
    background: linear-gradient(to bottom, var(--swatch-06) 0%, var(--swatch-01) 80%);
    color: var(--swatch-12);
    font-weight: var(--weight-03);
    box-shadow: 0 0.0625rem 0.25rem 0 rgba(31, 38, 135, 0.37);
    padding: 0.0625rem 0.5rem;
    border-radius: 0.5rem;
    
    border: 0.0625rem outset var(--swatch-01);
}

#event-list-preview .events .card > .forward > .event-view_vibe .link_to_vibe > svg {
    margin-right: 0.25rem;
}

#event-list-preview .event_image img, 
#event-list-preview .event_image video {
    width: 100%;
    border-radius: 0.75rem;
}

#event-list-preview .events {
    display: inherit !important;
}

.small_event {
    height: 6rem;
    width: 6rem;
}

.small_event > .event_image {
    position: inherit;
    height: 6rem;
    border-radius: 0.75rem;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(31, 38, 135, 0.37);
}

.small_event > .event_image > div {
    height: 6rem;
    width: 6rem;
}

@media (max-width: 600px) {
    .card {
        margin: 1rem;
    }
}