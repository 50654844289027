/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
  
  .screen {
    position: relative;
  }
  
  
  @keyframes simpler-pan-image_one {  
    0% {
      background-position: 100% 0%;
      background-size: 100%;
    }
    
    50% {
      background-position: 0% 100%;
      background-size: 200%;
    }
    
    90% {
      background-position: 100% 0%;
      background-size: 100%;
    }

    100% {
      background-position: 100% 0%;
      background-size: 100%;
    }    
  }

  /* 
  
  IDEA: How would we create a data structure for creating paramaters 
  on the React Component side, something like this? 
  
  4 Key Frames Example: 
  (
    e.g. Datastructure: 
    [<key_frame>, <bkgd_x>, <bkgd_y>, <bkgd_size>]
  )

  const keyFrameConfig = [
    [0, 0, 100, 100],
    [50, 100, 0, 200],
    [90, 0, 100, 100],
    [100, 0, 100, 100],
  ]

  */

  @keyframes simpler-pan-image_two {  
    0% {
      background-position: 0% 100%;
      background-size: 100%;
    }
    
    50% {
      background-position: 100% 0%;
      background-size: 200%;
    }
    
    90% {
      background-position: 0% 100%;
      background-size: 100%;
    }

    100% {
      background-position: 0% 100%;
      background-size: 100%;
    }    
  }

  @keyframes simpler-pan-image_three {  
    0% {
      background-position: 0% 100%;
      background-size: 100%;
    }
    
    10% {
      background-position: 0% 100%;
      background-size: 100%;
    }
    
    50% {
      background-position: 100% 0%;
      background-size: 200%;
    }

    100% {
      background-position: 0% 100%;
      background-size: 100%;
    }    
  }

  @keyframes pan-image {  
    0% {
      background-position: 36% 42%;
      background-size: 200%;
    }
    
    20% {
      background-position: 30% 35%;
      background-size: 200%;
    }
    
    20.0001% { /* -- View 2 -- */
      background-position: 60% 85%;
      background-size: 500%;
    }
    
    40% {
      background-position: 49% 81%;
      background-size: 500%;
    }
    
    40.0001% { /* -- View 3 -- */
      background-position: 80% 42%;
      background-size: 300%;
    }
    
    60% {
      background-position: 84% 33%;
      background-size: 300%;
    }
    
    60.0001% { /* -- View 4 -- */
      background-position: 0% 0%;
      background-size: 300%;
    }
    
    80% {
      background-position: 15% 4%;
      background-size: 300%;
    }
    
    80.0001% { /* -- View 5 -- */
      background-position: 80% 10%;
      background-size: 300%;
    }
    
    100% {
      background-position: 72% 14%;
      background-size: 300%;
    }
  }
  
  .screen > .screen-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    left: 0px;
    top: 0px;
    background-size: 300%;
    background-position: 0% 0%;
    animation: simpler-pan-image 15s linear infinite;
  }
  