/*
Copyright © 2024 Revibe Life LLC. All rights reserved.

This file is part of the Revibe project. Unauthorized copying,
distribution, or modification of this file, via any medium, is
strictly prohibited. This code is proprietary.

Created by Revibe Life LLC while leveraging AI technology.

*/
.no-events {
    z-index: 2;
    margin: 0rem 0.5rem 0.5rem 0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    border-color: var(--fc-border-color);

    border: 0;

}

.disclaimer {
    padding: 1rem 0rem;
    text-align: center;
    margin: 0;
}

.fc {
    max-height: 31.7rem !important;
    overflow-y: auto;
}

.fc table {
    font-size: 0.85rem !important;
}

.fc-toolbar {
    margin-bottom: 0.25rem !important;
    padding: 0.25rem 0.5rem;
}

.fc-toolbar-title {
    font-size: var(--size-05) !important;
}

.fc-button-primary {
    background-color: var(--swatch-04) !important;
    border-color: var(--swatch-04) !important;
    border: 0 !important;
    color: var(--swatch-10) !important;
}

.fc-view-harness {
    margin: 0.5rem !important;
    margin-bottom: 0rem !important;
}

.fc-daygrid-day.fc-day-today {
    background-color: var(--swatch-10) !important;
}

.fc-daygrid-day {
    background-color: var(--swatch-09) !important;
}

.fc-h-event {
    background-color: none !important;
    border: 0px !important;
} 

.event-calendar_item.active,
.fc-h-event > .fc-event-main > .event-calendar_item.active {
    background: -moz-linear-gradient(to top right, var(--swatch-01) 10%, var(--swatch-07) 90%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(to top right, var(--swatch-01) 10%, var(--swatch-07) 90%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top right, var(--swatch-01) 10%, var(--swatch-07) 90%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    background-color: var(--swatch-01) !important;
    color: var(--swatch-12) !important;
    font-weight: var(--weight-03) !important;

    overflow: default;
    height: fit-content;
    
    box-shadow: 0 0.125rem 0.25rem 0 var(--swatch-08) !important;
}

.fc-view {
    background-color: var(--swatch-12) !important;
}

.fc-daygrid-dot-event {
    padding: 0 !important;
}

.fc-col-header-cell {
    background-color: var(--swatch-12);
}

.fc-col-header-cell-cushion {
    padding: 0.25rem !important;
    max-width: 3rem;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td, .fc-theme-standard th {
    border-color: var(--swatch-04) !important;
}

.fc-theme-standard td {
    background-color: var(--swatch-10) !important;
}

.event-calendar_item {
    background-color: var(--swatch-06) !important;
    color: var(--swatch-10) !important;
    font-weight: var(--weight-01) !important;
    border-radius: 0.25rem;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
    cursor: pointer;
    width: 100%;
    font-size: 0.75rem;

    overflow: hidden;
    height: fit-content;

    box-shadow: 0 0.125rem 0.25rem 0 var(--swatch-08) !important;
}

.event-calendar_item:hover {
    background-color: var(--swatch-01) !important;
    color: var(--swatch-10) !important;
    
    box-shadow: 0 0.125rem 0.25rem 0 var(--swatch-08) !important;
}

.calendar-microcopy {
    text-align: center;
    padding: 1.5rem 0;
    margin: 0;
}

@media (max-width: 768px) {
    .fc {
        overflow-y: auto;
    }

    .fc-today-button {
        display: none !important;
    }
}